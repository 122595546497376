.time-block {
	min-height: 100%;
	box-sizing: border-box;
	border-left: 1px dashed rgba(55, 55, 58, 0.4);
	color: #777;
	position: absolute;
	top: 0;
	bottom: 0;
	padding-left: 10px;
	padding-top: 10px;
	pointer-events: none;
	white-space: nowrap;
}
