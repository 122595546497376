.rg-cell {
    .correction-icon {
        z-index: 1;
        left: 5px !important;
        top: 5px !important;
        position: absolute !important;
        cursor: pointer !important;
        outline: none !important;
    }
}
        