.time-picker {
  display: flex;
  flex-wrap: nowrap;

  &-node {
    flex-grow: 1;
  }
  &-controls {
    font-size: 0;
    white-space: nowrap;

    .btn {
      margin: 5px 0 0 4px;
      padding: 0;
      width: 20px;
      height: 21px;
      line-height: 19px;
    }
  }
}