.filter-section {
    font-size: 12px;
    position: relative;
}

.text-filter-row {
    margin-bottom: 5px;
}

.function-filter {
    margin-bottom: 5px;
}