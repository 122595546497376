.navbar {
	&-version {
		font-size: 10px;
		position: absolute;
		left: 0;
		bottom: -7px;

		span {
			font-size: 9px;
		}
	}

	&-brand {
		position: relative;
	}

	&-menu {
		flex: 1;
		display: flex;
		align-items: center;

		&-dropdown {
			flex: 1;
			display: flex;
			align-items: center;
		}
	}

	&-toggler {
		display: none;
	}

	@media screen and (max-width: 767px) {
		z-index: 50;

		&-toggler {
			display: block !important;
		}
		&-menu {
			position: relative;
			flex: 0;
			margin-left: auto;

			&-dropdown {
				position: absolute;
				top: 100%;
				right: .5rem;
				display: none;
				min-width: 10rem;
				padding: 0.5rem 0;
				margin: 0.125rem 0 0;
				font-size: 1rem;
				color: #212529;
				background-color: #fff;
				background-clip: padding-box;
				border: 1px solid rgba(0, 0, 0, 0.15);
				border-radius: 0.25rem;

				&.is-open {
					display: block;
				}
			}

			.navbar-nav {
				flex-direction: column;

				.nav-link {
					color: #343a40 !important;
					padding: 0.5rem 1rem;
					font-size: 16px;
					line-height: 18px;

					&.active {
						font-weight: 500;
					}
				}

				.nav-item.active .nav-link {
					font-weight: 500;
					background: rgba(0, 0, 0, .03);
				}
			}

			&-user.navbar-nav {
				border-top: 1px solid rgba(0, 0, 0, 0.15);
				margin: .5rem 0 0;
				padding: .5rem 0 0;

				.nav-link {
					font-weight: 400;
					font-size: 12px;
					line-height: 16px;
					padding: 0.25rem 1rem;
					background: #fff !important;

					span {
						display: block;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}

		}
	}
}
