.month-picker {
    display: flex;

   
}

.month-picker-dropdown {
    flex-grow: 1;
    margin: 0 10px;

    .progress {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        z-index: 1;
        opacity: 0.3;
    }
}

.month-picker-prefix {
    .period-status{
        position: absolute;
        right: 5px;
        font-size: 1.25em;
        margin-top: -4px;

        &.pending {
            color: gray;
        }
        &.imported {
            color: rgb(0, 136, 146);            
        }
        &.released {
            color: rgb(41, 255, 41);            
        }
        &.ready {
            color: #28a745
        }
        &.failed {
            color: rgb(255, 41, 41);            
        }
    }
}