.scroll-sync-container {
    box-sizing: border-box;
	padding: 10px;
	width: 100%;
	height: 200px;
	display: flex;
	justify-content: center;
	overflow: hidden;
}

.outer {
	width: 100%;
	overflow-y: auto;
	position: relative;
}

.inner {
	// box-sizing: border-box;
	// height: 300px;
	// width: 100%;
	// border: 1px solid black;
}