.tooltip-inner {
	text-align: left;
}
.popper {
	padding: 6px;
	transition: opacity linear .1s;

	&[data-popper-placement^='top'],
	&[data-popper-placement^='bottom'] {
		.arrow {
			width: 12px;
			height: 6px;
		}
	}

	&[data-popper-placement^='top']>.arrow {
		bottom: 0;

		&:before {
			border-width: 6px 6px 0;
			border-top-color: #000;
		}
	}

	&[data-popper-placement^='bottom']>.arrow {
		top: 0;

		&:before {
			border-width: 0 6px 6px;
			border-bottom-color: #000;
		}
	}


	&[data-popper-placement^='left'],
	&[data-popper-placement^='right'] {
		.arrow {
			height: 12px;
			width: 6px;
			margin-top: -2px;
		}
	}

	&[data-popper-placement^='left']>.arrow {
		right: 0;

		&:before {
			border-width: 6px 0 6px 6px;
			border-left-color: #000;
		}
	}

	&[data-popper-placement^='right']>.arrow {
		left: 0;

		&:before {
			border-width: 6px 6px 6px 0;
			border-right-color: #000;
		}
	}
}