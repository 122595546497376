.btn-loader {
    position: relative;
  
    &:before {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(255, 255, 255, .7);
      text-align: center;
    }
  
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      text-align: center;
      margin: -9px 0 0 -9px;
      font-size: 18px;
      line-height: 18px;
      color: #6c757d;
      content: "\F110";
      font-family: 'FontAwesome', sans-serif;
      @include animation(spin .7s infinite linear, spin2 .7s infinite linear)
    }
  }
  
  @-webkit-keyframes spin2 {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    from {
      transform: scale(1) rotate(0deg);
    }
    to {
      transform: scale(1) rotate(360deg);
    }
  }