.arrow-button {
    background-color: transparent;
    border: 1px solid #ddd;
    outline: none;
    min-width: 20px;
    min-height: 20px;
    padding: 4px;

    display: flex;
    align-items: center;

    &:active:not(:disabled) {
        border-color: #5c95c5;
    }

    &:disabled {
        cursor: not-allowed;
    }
}