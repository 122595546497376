.customized-scroll {
    // overflow: scroll;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 16px;             /* ширина scrollbar */
    }

    &::-webkit-scrollbar-track {
        // background: orange;        /* цвет дорожки */
    }

    &::-webkit-scrollbar-thumb {
        background-color: #cdcdcd;    /* цвет плашки */
        // border-radius: 20px;       /* закругления плашки */
        // border: 3px solid orange;  /* padding вокруг плашки */
    }
}

