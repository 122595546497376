.choose-file-btn {
	color: gray; 
	cursor: pointer;

	svg {
		vertical-align: text-top;
	}
}

.import-button-icon {
	vertical-align: baseline;
	margin-right: 5px;
}

.import-section {
	display: flex;
	justify-content: space-between;
}


.error {
	color: red;
}

.button-align {
	display: block;
	margin: 0 auto;
}