.main-nav-brand {
	position: relative;
	height: 19px;
	width: 130px;

	background-size: 120px 20px;
	background-repeat: no-repeat;
	background-position: center;

	a {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}
    