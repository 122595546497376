// $row-background-color-active: #97BDF1;
$row-background-color-active: rgba(255, 193, 7, 0.733);

.trow {
    display: flex;

    &.selected {
        background-color: $row-background-color-active !important;
    }
 
}  
.total-row .trow{
    height: 25;
    font-weight: 700;
}

.grouping-row {
    background-color: #ddd;
    padding: 5px;
}