.contextMenu {
  position: fixed;
  background: white;
  box-shadow: 0 2px 10px #999999;
  z-index: 100;
  line-height: 1.5;
  
  &-flex{
    display: flex;
  }
  
  &--option {
    padding: 6px 20px 5px 10px;
    min-width: 160px;
    cursor: pointer;
    font-size: 12px;
    position: relative;

    &:hover {
      background-color: #d8eafd;
    }

    &:active {
      background-color: #d8eafd;
    }

    &.disabled {
      color: #999999;
      pointer-events: none;
    }
  
    &.with-children {
      &:after {
        position: absolute;
        right: 3px;
        top: 50%;
        height: 14px;
        width: 14px;
        margin-top: -7px;
        font-family: 'Font Awesome 5 Free', serif;
        content: "\F054";
        font-weight: 900;
        line-height: 14px;
        text-align: center;
      }
  
      .children-wrap {
        position: absolute;
        display: none;
        left: 100%;
        top: 0;
      }
  
      .children-wrap-top {
        bottom: 0;
        top: initial;
      }
  
      &.hover {
        & > .children-wrap {
          display: block;
        }
      }
    }
  
    a {
      color: black;
      text-decoration: none;
    }
  }
  
  &--separator {
    width: 100%;
    height: 1px;
    background: #CCCCCC;
    margin: 0 0 0 0;
  }

  &--copy {
    display: inline-block;
    border-left: 1px dashed #333;
    margin-left: 5px; 
    padding-left: 5px;
  }
  
  &--action {
    padding: 6px 0px;
    min-width: 60px;
    cursor: pointer;
    font-size: 14px;
    position: relative;
    
    .phone {
      &-wrapper {
        display: flex;
        justify-content: space-between;
      }

      &-header {
        margin-right: 5px;
      }

      &-number {
        color: #007bff;
      }
    }    

    & .toggle-phone,
    & > span {
      display: inline-block;
      font-size: 0;
      line-height: 0;
      padding: 0;
      text-align: center;
      color: #007bff;
      border: none;

      .far, .fa {
        font-size: 14px;
        line-height: 5px;
        color: #007bff;
      }

      & .far::before,
      &.far:before,
      & .fa::before,
      &.fa:before {
        font-size: 14px;
        line-height: 5px;
        padding: 10px 10px 9px 10px;      
      }     

      & .fa:hover,
      &.fa:hover,
      & .far:hover {
        &::before{
          background-color: rgb(228, 225, 225); 
        }
      }

      &:hover {
        color: #0056b3;

        .far, .fa {
          color: #0056b3;
        }
      }
    }
  }
}