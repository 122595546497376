.modal-body.create-rule-dialog{
	font-size: 14px;
	
	.row {
		margin-bottom: 15px;

		&.correction-info {
			margin-bottom: 0;
		}
	}
}