$function-button-selected: #5c95c5;
// $function-button-selected: #ffc107;

.filter-button,
.contract-button,
.function-button {
    padding: 0.1em 0;
    border: 1px solid #ddd;
    font-size: 12px;
    flex-grow: 1;
    text-align: center;
    cursor: pointer;
    user-select: none;

    
    &.selected {
        color: $function-button-selected;
        border-color: $function-button-selected;
        font-weight: 600;
    }
}
