.scroll {
    overflow-y: scroll;
}

.inner {

}

.item:nth-child(2n+1) {
    // background-color: lightblue;
}

.item {
    height: 40px;
    box-sizing: border-box;
    padding: 5px;
}