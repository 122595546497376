@mixin user-select($value) {
  -webkit-user-select: $value;
  -khtml-user-drag: $value;
  -khtml-user-select: $value;
  -moz-user-select: $value;
  -moz-user-select: -moz- $value;
  -ms-user-select: $value;
  user-select: $value;

}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin opacity($value) {
  $IEValue: $value*100;
  opacity: $value;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
  filter: alpha(opacity=$IEValue);
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow($value) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

@mixin scale($scale) {
  @include transform(scale($scale));
}

@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

@mixin transform-origin($origin) {
  moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}

@mixin animation($anim, $webKit-amin) {
  -animation: $anim;
  -webkit-animation: $webKit-amin;
}

@mixin font-face($family,$src,$style: normal,$weight: normal) {
  @font-face {
    font-family: $family;
    src: url('#{$src}.eot'); // IE9 compat
    src: url('#{$src}.eot?#iefix') format('embedded-opentype'), // IE8 and below
    url('#{$src}.woff') format('woff'), // standards
    url('#{$src}.ttf') format('truetype'), // Safari, Android, iOS
    url('#{$src}.svg##{$family}') format('svg'); // legacy iOS

    font-style: $style;
    font-weight: $weight;
  }
}
