.correction-cell{
	.input-cell {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		text-align: right;
		height: auto !important;

		&:not(:focus) {
			border: none;
			background: none;	
		}
	}

	.correction-icon {
		z-index: 1;
		left: 5px;
		top: 5px;
		position: absolute;
		cursor: pointer;
		outline: none;
	}

	.icon-disabled {
		color: #acacac!important;
		cursor: default!important;
	}
}