.form-element-error-container {
	position: relative;
	width: 100%;
}

.error-icon {
	color: #dc3545;
	cursor: pointer;
	position: absolute;
	right: 5px;
	top: 5px;
}