.file-row {
	margin-bottom: 5;

	&-name {
		display: flex;
		justify-content: space-between;
		margin-bottom: 5px;
	}
}

.import-error {
	color: red;
	font-size: 12px;
}