.input-cell {
	height: 100%;

	&:not(:focus) {
		border-color: transparent;
		background: none;	
	}
}

.form-element-error-container {
	.input-cell.is-invalid {
		padding-right: 20px;
	}
}