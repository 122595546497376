.release-period-dialog {
  display: flex;
}

.release-period-dialog > * {
  flex: 1;
}

.release-period-dialog > .duty-check-status {
  flex: 0;
}
