.impersonation {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 30px;
	cursor: pointer;

	.red-icon {
		color: red;
	}
}