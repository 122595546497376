.simple-select {
	display: block;
	width: 100%;
	font-size: 12px;
	padding: .2rem .25rem;

	border-color: hsl(0, 0%, 80%);
	border-radius: 0;
	border-style: solid;
	border-width: 1px;
	outline: 0;

	&:focus {
		// @include box-shadow(0 0 0 1px #2684FF);
		box-shadow: 0 0 0 1px #2684FF;
		border-color: #2684FF;
	}

	&:invalid,
	&.is-invalid {
		box-shadow: 0 0 0 1px #dc3545;
		border-color: #dc3545;
	}
}