.main-nav {
	display: flex;
	line-height: 1.15;

	background: #f5f5f5;
	border-bottom: solid 1px #999;
	font-weight: 500;

	.nav-item {
		border-right: 1px solid #999;
		flex-shrink: 0;
	}

	.space {
		flex-grow: 1;
		flex-shrink: 1;
		border-right: none;
	}

	.logout,
	.impersonation,
	.main-nav-group {
		&:hover {
			background-color: #eee;
		}
	}
}