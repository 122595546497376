.tab {
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
    margin-bottom: -1px;

    background-color: #fff;
    border-bottom: 1px solid #dee2e6;
    
    &.active {
        border-width: 1px;
        border: 1px solid #dee2e6;
        border-bottom: 1px solid #fff;
    }
}