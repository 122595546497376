.help-link {
	position: relative;
	width: 30px;
	display: flex;
	justify-content: center;
	align-items: center;

	.icon {
		color: #4f94cd;
	}

	a {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}