.cell {
    &.released-different::after {
        content: '';
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-color: transparent #fa0300 #fa0300 transparent;
        border-style: solid;
        border-width: 4px;
        position: absolute;
    }
}
        