@import "../../../../Scss/mixins";

.switcher {
  display: inline-block;
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
  }

  input:checked + span:after {
    left: 20px
  }

  input:checked + span:before {
    content: '';
    background: #007AFF;
    border: 2px solid #007AFF
  }

  span {
    display: block;
    margin: 0;
    width: 42px;
    height: 24px;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 42px;
      height: 24px;
      background: #EFEFF4;
      border: 2px solid #f1f1f1;
      @include border-radius(40px);
      @include transition(all .2s linear)
    }

    &:after {
      content: '';
      height: 20px;
      width: 20px;
      position: absolute;
      left: 2px;
      top: 2px;
      background: #fff;
      @include box-shadow(0 2px 2px rgba(0, 0, 0, 0.5));
      @include border-radius(2.5rem);
      @include transition(all .1s linear);
    }
  }

  &.small {
    input:checked + span:after {
      left: 16px
    }
    span {
      width: 34px;
      height: 20px;
      &:before {
        width: 34px;
        height: 20px;
        @include border-radius(32px);
      }
      &:after {
        height: 16px;
        width: 16px;
      }
    }
  }
}
