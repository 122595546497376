.filter-popup {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 1000;
    padding: 4px 0;
    border: 1px solid rgba(0, 0, 0, .2);
    font-weight: 400;
    line-height: 1.5;
    border-radius: 0.3rem;
    font-size: 12px;
    white-space: nowrap;
    overflow: auto;

    .sort-item-wrapper {
        padding: 3px 6px;
        cursor: pointer;
                    
        &.active {
            background-color: rgba(255, 193, 7, 0.314);
        }
                    
        span {
            margin-left: 5px;
        }
    }

    .filter-item-wrapper {
        display: flex;
        align-items: center;
        padding: 3px 6px;
        margin-bottom: 0;
        cursor: pointer;

        span {
            margin-left: 5px;
        }
    }
}
