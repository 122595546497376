.functionary-filter {
    margin-left: 8px;
    input {
        margin-right: 6px;
    }
    label {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        user-select: none;
        cursor: pointer;
    }
}