$border-width: 1px;

$border-color: #ddd;
$row-background-color: #f5f5f5;
$row-background-color-active: #97BDF1;
$row-background-color-hover: #ffc107;

.data-grid {
    font-size: 12px;
    border: $border-width solid $border-color;
    display: flex;
    flex-direction: column;
    position: relative;


    .trow,
    tr {
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
    }

    .thead,
    thead {
        flex-shrink: 0;
        .trow,
        tr {
            height: 100%;
            font-weight: 600;

            // text-transform: uppercase;

            &:nth-child(2n-1) {
                background-color: $row-background-color;
            }

        }
    }

    .tbody, 
    tbody {
        flex-grow: 1;
        height: 100%;
        overflow-x: hidden;

        

        .trow,
        tr {
            &:nth-child(2n-1) {
                background-color: $row-background-color;
            }
            // &:hover {
            //     background-color: $row-background-color-hover;
            // }
        }
    }
    .total-row {
        flex-shrink: 0;
        flex-basis: 25px;
    }
    
    .no-data-paceholder {
        display: flex;
        padding: 20px;
        align-content: center;
        justify-content: center;
    }

    .cell {
        // background-color: #fff;
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
    }

    .thead,
    .total-row {
        .cell {
            &:last-child {
                border-right: none;
            }
        }
    }

    // th, td {
    //     min-width: 10px;
    //     border-left: $border-width solid $border-color;
    //     border-right: $border-width solid $border-color;
    // }
    
    .cell,
    .tcol,
    td {
        input {
            width: 100%;
            height: 23px;
            line-height: 20px;
            // border: none;
            
        }
    }
    
    .filter-icon {
        position: absolute;
        bottom: 2px;
        right: 2px;
        color: hsla(0, 0%, 58.4%, .5);
        cursor: pointer;

        &.active {
            color: #5c95c5;
        }
    }
}

.clear-filters-icon {
    position: absolute;
    top: -27px;
    right: 0;
    z-index: 1;
    height: 20px;
    width: 20px;
    color: #dc3545;
    cursor: pointer;
}

.scroll-indent {
    overflow-y: scroll;
}


.data-grid {
    .outer {
        overflow-y: scroll;
    }
}