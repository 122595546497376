.cell {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 3px;
    min-height: 100%;

    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    cursor: default;

    &.text{
        display: block;
    }
}

.thead .cell {
    white-space: normal;
    word-break: break-word;
    flex-direction: column;
}

.tbody .cell {
    align-items: center;
}

.resizer {
    height: 100%;
    background-color: transparent;
    width: 3px;
    position: absolute;
    top:0;
    bottom: 0;
    right: 0;
    // transform:translateX(50%);
    cursor: col-resize;
    z-index: 999;

    &:hover {
        background-color: blue;
    } 
}