.nav-group {
	position: relative;

	&-name {
		display: block;
		font-size: 12px;
		line-height: 19px;
		height: 19px;
		padding: 0 10px;
		text-transform: uppercase;
		cursor: pointer;

		a {
			padding: 0;
			color: inherit;
		}
	}

	&:hover {
		.nav-links {
			display: block;
			z-index: 10;

			&:empty {
				padding: 0;
				z-index: 10;
				border-width: 1px 0 0;
				overflow: hidden;
			}
		}
	}

	.nav-link {
		&.active {
			font-weight: 700;
		}
	}

	.nav-links {
		display: none;
		position: absolute;
		top: 20px;
		background: #eee;
		padding: 2px 0 6px;
		min-width: 100%;
		border: solid 1px #999;
		border-top-color: transparent;

		left: -1px;

		.nav-link {
			display: block;
			padding: 2px 10px;
			color: #000;
			font-size: 12px;
			line-height: 16px;
			white-space: nowrap;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}

			&.active {
				font-weight: 700;
			}

			&.hide {
				display: none;
			}
		}

		.nav-link-separator {
			width: 100%;
			height: 1px;
			background: #999;
			margin: 0 0 0 0;
		}
	}
}

.has-links:hover {
	border-bottom: 1px solid transparent;
	margin-bottom: -1px;
	border-bottom: 1px solid #eee;
}
