.main-nav-application {
	font-size: 12px;
	line-height: 19px;
	padding: 0 15px;

	.app-version {
		color: green;
	}

	&.production {
		background: #abd2ab;

		.app-name {
			color: yellow;
		}

		.app-version {
			color: blue;
		}
	}

	&.staging,
	&.test {
		background: #f8abab;

		.app-name {
			color: yellow;
		}
	}

	&.development {
		background: #bbd6f3;

		.app-name {
			// color: $errorRed;
			color:red;
		}
	}
  }

.main-nav-version {
	display: inline-block;

	.exclamation {
		padding-left: 10px;
		color: red;
	}
}

.app-function {
	margin-right: 15px;
}

@media (max-width: 1160px) {
	.app-function {
		display: none;
	}
}