.import{
	&-button-icon{
		vertical-align: baseline;
		margin-right: 5px;
	}

	&-section{
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
		& .file-input{
			display: none;
		}
		& .text{
			margin-left: 10px;
		}
	}
	&-warning{
		display: flex;
		align-items: center;
		font-weight: 600;
		font-size: 14px;
		&>*{
			color: orange;
		}
	}

	&-month-picker{
		flex: 1;
		margin-bottom: 5px;
	}
}
