.loader {
	display: flex;
	justify-content: center;
	align-items: center;

	background-color: rgba(255, 255, 255, .8);

	// &-icon {
	// 	color: #999;
	// 	animation: spin .9s infinite linear;
	// }

	&-fill-container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	
	&-full-page {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		font-size: 64px;
	}

	&-suspense {
		background: transparent;
	}

	&-extra-small {
		font-size: 20px;
	}
	
	&-small {
		font-size: 32px;
	}

	&-medium {
		font-size: 45px;
	}

	&-large {
		font-size: 64px;
	}

	&-default {
		font-size: 45px;
	}
}

.loader-icon {
	color: #999;
	animation: spin .9s infinite linear;
}

@keyframes spin {
	from {
		transform: scale(1) rotate(0deg);
	}
	to {
		transform: scale(1) rotate(360deg);
	}
}