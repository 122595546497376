$bg-color: #1E1E20;
$link-color: #DC3522;
$work-color: #B64926;
$edu-color: #FFB03B;

.timeline {
  width: 100%;
  position: relative;
  overflow: hidden;

  .time-block {
    min-height: 100%;
    box-sizing: border-box;
    border-left: 1px dashed rgba(55, 55, 58, 0.4);
    color: #777;
    position: absolute;
    top: 0;
    bottom: 0;
    padding-left: 10px;
    padding-top: 10px;
    pointer-events: none;
    white-space: nowrap;
  }

  .time-block:first-child {
    margin-left: 30px;
  }

  .events {
    padding-top: 55px;
    padding-left: 30px;
  }

  .event {
    padding-bottom: 10px;
    vertical-align: middle;
    white-space: nowrap;
    width: 100%;

    b {
      padding-right: .5em;
    }

    .time {
      display: inline-block;
      height: 8px;
      background: #444;
      border-radius: 2px;
      margin-right: 10px;
      opacity: .5;
      position: relative;
      min-width: 2px;

      &-bug {
        position: absolute;
        top: -5px;
        left: -20px;
        color: red;
      }
    }
  }

  .event-selected {
    font-weight: 600;

    .time {
      box-shadow: 0px 0px 4px 0px #1CFF00;
    }
  }

  .event:hover .time {
    opacity: .8;
  }
}

.event-details {
  padding-top: 20px;

  .sql-hl-keyword {
    font-weight: bold;
    color: blue;
  }

  .sql-hl-string{
    color: red;
  }
}

.request-decode-error{
  color: red;
  font-weight: bold;
}

.request-list {
  height: 300px;
  overflow-x: hidden;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid #ccc;

  .request-item {
    white-space: pre;
    position: relative;
    padding: 0 5px;
    cursor: default;

    &.selected {
      font-weight: bold;
      background-color: #F0F0F0;
    }

    &.with-exception {
      color: red;
    }

    &:hover {
      background-color: #D0D0D0
    }

    .request-time {
      position: absolute;
      right: 0;
      z-index: 1;
      top: 3px;
    }
  }
}

.requests-filter {
  float: right;

  input {
    margin: 0;
    padding: 0 .25rem;
    width: 200px;
    height: 1.75rem;
  }
}

.requests-picker{
  .requests-clear {
    cursor:pointer;
    margin-left:10px;
  }
}

@import "../mixins";