.request-timeline {
    .event {
        b {
            padding-right: .5em;
        }
    
        .time {
            display: inline-block;
            height: 8px;
            background: #444;
            border-radius: 2px;
            margin-right: 10px;
            opacity: .5;
            position: relative;
            min-width: 2px;
    
            &-bug {
                position: absolute;
                top: -5px;
                left: -20px;
                color: red;
            }
        }
    }

    .event-selected {
        font-weight: 600;
    
        .time {
            box-shadow: 0px 0px 4px 0px #1CFF00;
        }
    }

    .event:hover .time {
        opacity: .8;
    }
}